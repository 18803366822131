import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild, signal, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FormControl, Validators } from '@angular/forms';
import { Certified, LcpTracker} from '../../interfaces/auth-interface';
import { environment } from 'src/environments/environment';

export const FORTIFY_2_2: string = '2_2';
export const FORTIFY_NET_6: string = '6';
export const SOURCE_TYPE: number = 0;

export const ERROR_MESSAGE ={
  LOCATION :'We have identified that you are attempting to access LCPtracker at a location outside of the U.S. Please contact LCPtracker Support for further assistance.',
  TIMEOUT: 'Connection timed out. Please wait a couple more seconds, or refresh the page and try again.'
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'username-credentials',
  templateUrl: './username-credentials.component.html',
  styleUrls: ['./username-credentials.component.scss'],
})
export class UsernameCredentialsComponent implements AfterViewInit, OnDestroy {
  isDesktop: boolean = false;
  action: string = 'delete';
  username: string = '';
  bottomSheetRef!: MatBottomSheetRef<UsernameCredentialsComponent>;
  matDialogRef!: MatDialogRef<UsernameCredentialsComponent>;
  logo: string = 'assets/images/lcptracker-full-color.svg';
  userEmail = new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]);
  isAlertMessageON: boolean = false;
  trackerImg = './assets/images/icon_LCPtrackerPro.svg';
  certifiedImg = './assets/images/icon_LCPcertified.svg';
  trackerFlag: boolean = false;
  certifiedFlag: boolean = false;
  productSelection: boolean = false;
  contactLink = 'https://lcptracker.com/contact/contact-support';
  fortifyType: boolean = false;
  private timeoutId!: any;


  @ViewChild('usernameEmail') usernameEmail!: ElementRef<HTMLInputElement>;
  @Output() closeComponent: EventEmitter<boolean> = new EventEmitter();

  _isLoading = signal<boolean>(false);
  isLocationAvailable = false;
  ErrorMessage = '';
  isValidLocation = true;

  constructor(
    public authenticationService: AuthenticationService,
  ) { }

  ngAfterViewInit(): void {
    this.focusOnUsername();
  }

  /**
   * focus on username when dialog / bottom sheet opens up.
   */
  focusOnUsername(): void {
    if (this.usernameEmail !== undefined) {
      this.usernameEmail.nativeElement.focus();
    }
  }

  /**
 * Starts a timer for 30 seconds to show a timeout message if no response is received.
 */
  startTimeoutRequest(): void {
    this.timeoutId = setTimeout(() => {
      if (this.isValidLocation) {
        this.ErrorMessage = ERROR_MESSAGE.TIMEOUT;
      }
    }, 30000);
  }

 /**
 * Check if the user exists in LCPtrackerApi,
 * then calls retrieveCertifiedUserInfo if valid.
 */
userPortalLogin(): void {
  this.isValidLocation = true;
  this._isLoading.set(true);

  if (!this.userEmail.value) {
    return;
  }

  // Start a timeout request that waits for 30 seconds.
  // If the request takes longer than this, it will show a timeout message to the user
  this.startTimeoutRequest();

  this.authenticationService
    .checkUserExistsTrackerPro(this.userEmail.value)
    .subscribe({
      // Handle the successful response
      next: (res: LcpTracker) => {
        // Check if the user uses Fortify based on the response and set the appropriate flag
        this.fortifyType = res.UsesFortify;
        if (this.fortifyType) {
          // If the user uses Fortify, set trackerFlag to false
          this.trackerFlag = false;
        } else {
          // Otherwise, set trackerFlag to true
          this.trackerFlag = true;
        }
      },
      // Handle the error case
      error: (error) => {
        // If there's no status code in the error (e.g., timeout or no response), assume the user is outside the U.S.
        if (!(error.status)) {
          clearTimeout(this.timeoutId);
          this.isValidLocation = false;
          this._isLoading.set(false);
          // Display an error message to inform the user that access is restricted from outside the U.S.
          this.ErrorMessage = ERROR_MESSAGE.LOCATION;
          return; // Stop further execution if the location is invalid
        }
        // Log the error for debugging purposes if the user is not found in LCPtrackerApi
        console.error('User not found in LCPtrackerApi');
        // Assume the location is valid if other errors are encountered
        this.isValidLocation = true;
        // Retrieve additional user information if the user is valid
        this.retrieveCertifiedUserInfo();
      },
      complete: () => {
        // Once the process completes, retrieve the certified user information
        this.retrieveCertifiedUserInfo();
      },
    });
}


  /**
   * Check if user exists in CertifiedApi
   * then calls checkApiSourceResponse
   */
  retrieveCertifiedUserInfo(): void {
    if (!this.userEmail.value) {
      return;
    }

    this.authenticationService
      .checkUserExistsCertified(this.userEmail.value)
      .subscribe({
        next: (res: Certified) => {
          this.fortifyType = res.UsesFortify;
          if (this.fortifyType) {
            this.certifiedFlag = false;
          } else {
            this.certifiedFlag = true;
          }
        },
        error: () => {
          console.error('User not found in CertifiedApi');
          this.checkApiSourceResponse(this.trackerFlag, this.certifiedFlag);
        },
        complete: () => {
          this.checkApiSourceResponse(this.trackerFlag, this.certifiedFlag);
        },
      });
  }

  /**
   * Evaluate tracker and certified flags to select where
   * user wants to be redirected
   */
  checkApiSourceResponse(trackerFlag: boolean, certifiedFlag: boolean): void {
    if (this.fortifyType) {
      this.authenticationService.login(this.userEmail.value || '');
      return;
    }

    if (trackerFlag && !certifiedFlag) {
      //source type = 2 if different to 0, need to redirect fortify v2
      this.trackerRedirect();
      return;
    }

    if (!trackerFlag && certifiedFlag) {
      this.certifiedRedirect();
      return;
    }

    if (trackerFlag && certifiedFlag) {
      this.productSelection = true;
      return;
    } else {
      this.authenticationService.login(this.userEmail.value || '');
    }

    this.isAlertMessageON = true;
  }

  /**
   * If Username only exists in TrackerAPI this method is
   * called to redirect user from User Portal to Tracker CDN
   */
  trackerRedirect(): void {
    location.href = `${environment.applications.lcptracker.login}/login/login/${this.userEmail.value}/password`;
  }

  /**
   * If Username only exists in CertifiedAPI this method is
   * called to redirect user from User Portal to Certified CDN
   */
  certifiedRedirect(): void {
    location.href = `${environment.applications.certified.login}/login/login/${this.userEmail.value}/password`;
  }

  emitCloseComponentEvent() {
    this.closeComponent.emit(true);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }

}
